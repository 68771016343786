<template>
	<div class="content">
		<Header :title="$t('lang.about_title')"></Header>
		<div class="make">
			<img src="../../assets/img/download/logo.png" alt="">
			<p>{{ $t('lang.about_p1') }}</p>
		</div>
	</div>
</template>

<script>
	import Header from "@/components/Header"
	import HB from "@/assets/js/bridge"
	
	export default {
		components: {
			Header
		},
		data() {
			return {
				language: '',
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			localStorage.setItem('lang', this.language);
			this.$i18n.locale = localStorage.getItem("lang");
		},
		mounted() {
			
		},
		methods: {
			
		}
	}
</script>

<style>
	/* * {
		font-family: PingFang;
	} */
	.make {
		width: 92%;
		margin: 0.2rem auto;
	}
	img {
		display: block;
		width: 40%;
		margin: 0 auto;
	}
	p {
		font-size: 0.3rem;
		text-indent: 2em;
	}
</style>
